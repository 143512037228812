import React, { useState, useEffect } from 'react'
import { ScatterBoard } from './react_scatter_board'
import { read_tsv } from './utils'
import './App.css';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

function DirectionRenderer({ value }) {
  if (value === 'up') {
    return '<b style="font-size:200%;">&uarr;</b style="font-size:200%;">'
  } else {
    return '<b style="font-size:200%;">&darr;</b style="font-size:200%;">'
  }
}

function App() {
  const [plotData, setPlotData] = useState(undefined)
  const [tableData, setTableData] = useState(undefined)
  const [gridApi, setGridApi] = useState(undefined)
  const [gridColumnApi, setGridColumnApi] = useState(undefined)

  function onGridReady(params) {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
    params.columnApi.autoSizeAllColumns()
    params.api.setFilterModel({
      adjusted_pvalue: {
        filterType: 'number',
        type: 'lessThan',
        filter: 0.05,
      }
    })
    params.columnApi.applyColumnState({
      state: [
        { colId: 'rank', sort: 'asc' },
        { colId: 'adjusted_pvalue', sort: 'asc' },
      ],
      defaultState: { sort: null },
    })
  }

  useEffect(() => {
    ;(async () => {
      const { data } = await read_tsv('LM2_Deidentified_UMAP.tsv')
      const _data = []
      for (const row of data) {
        const [pid, type, cluster, visit] = row.sid.split('-')
        _data.push({
          x: row['UMAP-1']*1.0,
          y: row['UMAP-2']*1.0,
          sid: row.sid,
          pid: pid.slice(1),
          type,
          cluster: cluster.slice(1),
          visit: visit.slice(1),
        })
      }
      setPlotData(_data)
    })()

    ;(async () => {
      setTableData(await read_tsv('df_enrich.tsv'))
    })()
  }, [])

  return (
    <div className="App">
      <div className="header">
        <h1 className="title">Lyme MIND 2</h1>
        <p className="description">
          This interactive viewer allows you to explore the <a href="https://www.lymemind.org/">Lyme MIND 2</a> Patient Cohort in gene expression space. Each point corresponds to a participant in our study, there position was computed based on a UMAP projection of their RNAseq expression. Per-cluster enrichment analysis can be explored by clicking on a cluster to filter and review the table below. This was computed by performing differential expression between clusters and using the most significantly up and down regulated genes to query with <a href="http://maayanlab.cloud/Enrichr/">Enrichr</a>. The <a href="https://maayanlab.github.io/react-scatter-board/">react scatter board widget</a> is being used to render this plot, its widgets can also be used to change which aspect of the metadata is used to color or shape each point.
        </p>
      </div>
      <div className="canvas">
        {plotData === undefined ? (
          <div>Loading...</div>
        ) : (
          <ScatterBoard
            data={plotData}
            shapeKey="type"
            colorKey="cluster"
            labelKeys={['pid', 'type', 'cluster', 'visit']}
            searchKeys={['sid', 'pid', 'type', 'cluster', 'visit']}
            is3d={false}
            width={800}
            height={350}
            onClick={(evt, datum) => {
              gridApi.setFilterModel({
                ...(gridApi.getFilterModel() || {}),
                cluster: {
                  filterType: 'text',
                  type: 'equals',
                  filter: datum.cluster,
                },
              })
            }}
          />
        )}
      </div>
      <div className="table ag-theme-material">
        {tableData === undefined ? (
          <div>Loading...</div>
        ) : (
          <AgGridReact
            onGridReady={onGridReady}
            rowData={tableData.data}>
            {['rank', 'direction', 'library', 'term', 'adjusted_pvalue', 'category', 'cluster']
              .map((h, ind) =>
              <AgGridColumn
                key={h}
                colId={h}
                field={h}
                sortable
                cellStyle={{
                  'string': {textAlign: 'left'}
                }[tableData.dtype[h]]}
                filter={{
                  'int': 'agNumberColumnFilter',
                  'float': 'agNumberColumnFilter',
                  'string': 'agTextColumnFilter',
                }[tableData.dtype[h]]}
                type={{
                  'int': 'numericColumn',
                  'float': 'numericColumn',
                  'string': 'stringColumn'
                }[tableData.dtype[h]]}
                resizable
                valueFormatter={{
                  'int': undefined,
                  'float': ({ value }) => value.toPrecision(3),
                }[tableData.dtype[h]]}
                cellRenderer={{
                  'direction': DirectionRenderer,
                }[tableData.dtype[h]]}
                comparator={(valueA, valueB) => {
                  if (tableData.dtype[h] === 'int') {
                    return valueA - valueB
                  } else if (tableData.dtype[h] === 'float') {
                    return valueA - valueB
                  } else {
                    if (valueA > valueB) return 1
                    else if (valueA < valueB) return -1
                    else return 0
                  }
                }}
              />
            )}
          </AgGridReact>
        )}
      </div>
    </div>
  );
}

export default App;
