import * as THREE from 'three'

// generated according to https://stackoverflow.com/questions/6150289/how-to-convert-image-into-base64-string-using-javascript
const imageData = {
  circle:
    ',iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACtElEQVRYR+2Wz2sTQRTH5+2mNu2QbJY1Ymuym9nkInjwR/HgQRQVEf8Db9KDB0+K+CfovT0IIuKf4EVBUfSioGD1KJjdndmEEJrY1mQDadjdkZFWSkmT3aQShO5133vfz7z9znsLaMIPTFgfHQD8nx2wLOs6ABQ45/qWhxhCiBWLxRdxPRW5A/V6HXe73UdhGN7AGNclSTqWSCT+6Pm+j8IwrHqedxQAnhFCbgNALwpMJADbts8DwHNVVdV0Oo0A+qdxzlGr1ULr6+utIAiulUqlj8MghgLUarUF3/ff5vP59LBiO99Xq9W2LMsX5ufnVwblDQQol8snpqamPui6Hkt8W5Ax1uacnyWEfN8LYiAApbSZy+U0WZbjHP5vbBAEyHXdhmmaR2IDUErvzs7OPshms9MjqW8lNRqNzU6nc58QstSvzp4dYIx91TTtJMZ4HH3U6XTQ2traF13XF2IBWJYVEkJgL8dHpRI3g1IamqbZ9zv27YBt21eSyeTrubm5qDoD42q1Gur1ehcJIe93B/YFoJQ+yWQyi4qi7AvAxsaGmA+PDcO4FRlAUZTFTCYzGQDLsi7NzMy8mdgnEMfeLxOGYYgYY/FMKAAYYyuapp2a2DV0HOcOxvjhuINodXW11+127xmGsRxrDohgx3Ga+Xx+5FEs1nSlUmmappmNPYpFAqX0OELok2EYqVGug+u6nu/7p4vF4o+RALa8cAYh9E7X9VgQruv+Eus4l8t9G3kdbyeWy+Vzsiy/VFVVSaVSSJKkvjWF49vttpj9bYTQZdM0Pw/r3NAfku0CnPNDtm0vAcBNjHETAIQ3psWu8H1/k3P+0/O8w5IkPS0UCuKXLBwmLt5HBthZzHGcq0EQFADA4JwLIVeWZUYIeRVFdGfMSABxRcb2wH4KRlpG/1LwAGB3B34DBWQWMG24djEAAAAASUVORK5CYII=',
  cross:
    ',iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAA50lEQVRYR+2XPQ6DMAyF8QZbfIYchN4spRer6D1yBcyWbKmylZI4WB0oklmdn8cnvfcAupMfOPn+7noCvPeOo2atnSRURQTy5cMwuL7vi/tijCmE8LDW3o+KEAtARIeIxX1ElIhIBSiBixNo+HxExBvngmVZXgDwrNnwOyc2dmr5PB+KiKzFiag6L+XETgDn86PhUltXygkVoAT+jwBXt7/aMITQxRinz7re1ar3vtrlADAaY3IYVet4Xdc5pTQzQbQ5X78HlIASEBPgcqLk81aDigTkw7icyHPJP0FeLxbQeiPpXAW8AUg5MTAvQNb8AAAAAElFTkSuQmCC',
  diamond:
    ',iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACaElEQVRYR9WXy4vTUBTG05DW2E7sI6XPlOTeUkTcqeB2QHApIrgTXIr+AbrVlW5cKbidlStFXKsrl+IsB4T23tiGtolp2pLbTh9JkQwpjNppmkyHYFaB3POdX777OidCBXwQQhdpmv5GUdRisVhchxD+CCIVCRLkxGCMv+fz+SvOu6qq+wCAq0G0AgHU6/Xb8Xh8r1gsJp2knU5nOJ1O7wMAPvqFCASAMTYEQUgzDHOUz7IsqtVq9SGEmTMHwBg/5TjuCc/z7PFkvV5vYprmCwDAMz8QvhzQNG2HEKIDAM6tSoIxnrEsmy6VSuNNIXwBYIzf8zx/h+O4lfqmaVKGYbyTJOnu1gEajca1aDT6pVKpXFgnriiKadv2riRJ+5tAbOwAxvigUChcYtk/pv6fHJPJhOp2uwcAgMtbA0AI3UskEm9yudzOJqKqqpLRaPSgWq2+9Rq/kQMIoUNRFFmapr30jr7btk01m80xhDDhFeAJgBB6mUqlHqbT6fNeYse/9/v9w8Fg8BpC+Hhd3FoAjHEhEok0RFGM+0m+HCvL8tiyLKlWq/06KX4tgCzLn7PZ7I1EwtPJlfqEEErX9U8AgJu+ATDGuwzDfBAEIRXk75cxiqIM5vP5LQjh11U6JzqAEGqWy+VKLBY7TX5qNptR7Xb7JwBA+r8AQp8Cx65QF6EDEPo2dCCcgyiZTD7KZDLrL4G/VphhGJPhcPjqVAfRUjPUo9h1wddlpGkaIYRs7zJy10N417EDEHpB4roQXknmAIRelLourCzLdV2fEkKen2lZvtyWoTYmDkTorZk7FeE1p+7htJX2/DfV7WYwLPkGlAAAAABJRU5ErkJggg==',
  square:
    ',iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAp0lEQVRYR+2WMQ4CIRBFWQoyFQ03YG7naqPHsNEY9XLADSjooFrD1mJixmRj8qlh/ssbij+pjc+0cb4CAAx8NBBCOGitjfCjVu/9fTRjCNDDiehKRKI1tdaWWuuemR/vIIbDU0ona+3ZOScCyDkvpZQjM18AAAMwAAMwAAMw8HcGZmPM7UeFZGbm51cG+uUY404pJa1kbRTeM0RtR9gV1+cAgIHNDbwAdNviIRMkcw0AAAAASUVORK5CYII=',
  'triangle-down':
    ',iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAC2klEQVRYR+2WPWzTQBiGnR/iFrs4pFadpolyn09VJZAYOjDQCYmFiYVKIDHQgRWxMCAhIaSuHWCEAXWknZAQG1IHJAbEAggGcpc4bVK3Sdu0SUwSXBu5clHTpk7sJC0D3uLcfe9z730/9jGn/PhOWZ/5dwAURblgGEbiJBzx+XxZhNAPS+uvA5RSJRQK8f0GME3Tp+v6NgBAEwAh5DbHcS8lSeL6CbG2tlbVNG1GluXFJgDrRyaT+SZJ0sWBgYG+MNRqNUZV1a8AcGlfoCkJM5nMZCAQWIrH40P9IFheXq7ouj4ly/KXlgC2C4uRSOTm0FBvGXZ2dpjNzc3XAHDr4OGOlGE+nz9bq9W2ACDUSxcopbphGPz4+HjdEcD6M51OP+F5/pEoimwvIIrFYr1cLs/Ksjx7ON6xjSidTm/E4/FIMBjsikHXdWZlZWUDAMRWgZwAbrAsOz86Oip0Q6Cq6ramaXcwxm9dAdhX8VmSpMnBwUFPDJqmMcVi8VMymbx8XADHWUApnfD7/VYATyWRzWatspvEGP/0BGBtUhRlXhCEaUEQXNlQKpV+lUqlBQC462RfR9OQEGLKsuzqGiilDMa4bfy2C+zm9JDjuMeiKJ7rhKJQKJQ1TXuKEJprt74jADshV2OxWDQUcu5PjUaDyefzqwAQayd+ZBg5bSCEXGNZdmFsbOy807pcLrdVr9enMcbvewpgJ+SH4eHhKY5rPbGr1SpTKBSWAOBqJ+KuHLAWp1KpRDAY/J5MJlt+uCiKUg0EAhOJRCLXFwA7F56Fw+F74XC4qSztsnsBAA86FXftwH5gQkgDIXTG7/fvvTIMw/qY+Y0xdj1BO66Cg6cihMzwPP98ZGRk7yrW19crlUrlPsb4lZvTe3bA7g0kGo3udSdVVSlCCLsV7woglUpdYVn2nRVkd3f3OkLo44kC2GX5xjRNKx+mvYh35YBXwcP7PCVhr8T/O2A58AfGZw8wtSahoQAAAABJRU5ErkJggg==',
  'triangle-up':
    ',iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAC3UlEQVRYR+2VP2gTURzHLyG5tOnFXD0uJsTj3u9dB4s4iIIOIk5uDnUQQdChuApOBScFh4KDg4uDOgkKKoKO4iAI4lIciiD03rtoSnOJTbzcJTmS651EraTm312a2qVvvd/v+/2873vvdyFml1dol/2ZPYBtJUApfdk+QgCYG/UoRwZQVfVULBZ77Xke02w2zymK8n4UiJEBKKU0k8mgtmmhUKAIIfzfAAgh8xzH3RVFMdE2LRaLZq1Wu44xfhgUYqQEVFVtAUAkFPrd7rouo2maoyhKdMcBCCH3eJ6fn56enuw0q1QqjWq1+gAhdC0IRKAECCFyOBxelmWZ62WSy+WsjY2Nw4qifPULEQhA07QPoiiejMfjPfVrtRpTKpXeAcCZsQMQQs6yLPskm83uHyS+urpabjabFzHGb/xA+E6AUlrIZrMHotHB96zVajH5fF7HGKfHBkApXZiamrohiuI+P6KlUqlar9dvI4TuDKsfmoDneWFCSAtjHB4m1vmdEOIpijK0ZyiApmmPeZ6fSyaTvW9eHyrDMOqGYTyXZfnKIPCBAJqmzTIM81GW5V8TL+jK5XKG67onMMZf+vUOA1hKpVJHJye3zBzfHI1Gg9F1fQkAjgUGWFlZmYvH448ymQzv27FH4drammHb9mWM8ateOn0TIIRUJEniI5HIdvwZx3Haz7IMAIJvAErpzUQisSAIwsS23P80r6+v26ZpLgLArX/1uhIoFoucZVnfASA2DvNNjfZTZlk2KUlSo1O3C4BS+kIQhPOJxEgXvy+zaZpMuVx+hhC60BdAVdXj0Wj0rSRJviZe0ITy+bzpOM5pAPi02bslAUrp53Q6PTsxMZaj7+Kzbbv9LJcRQke6AFRVvcRx3P1UKtXzXx90t/3qdV23LMu6OjMz87Rd8zcBQojGsuyORN8J43leyHGcHwAAWwAopYdc1z04rp0OnP+h0LfN8Tz0Z7TTQHsAPwHv3BIw9zTkUQAAAABJRU5ErkJggg=='
}

export class Texture {
  constructor(symbolType) {
    this.symbolType = symbolType
  }

  load() {
    // Load a texture synchronously
    let image = new Image()
    image.src = 'data:image/png;base64' + imageData[this.symbolType]
    let texture = new THREE.Texture(image)
    texture.needsUpdate = true
    return texture
  }
}
export const symbolTypes = Object.keys(imageData)

